import { useState, useEffect, Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Seo, Header, Footer, MobileButtons } from '../';

import './layout.scss';

const Layout = ({ pageId, phone, booking, location, slug, pageTitle, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pageTitle]);

  return (
    <Fragment>
      <Seo
        data={{
          title: `Well Done Appliance Repair | ${location} | ${pageTitle}`,
        }}
      />
      <Header location={location} phone={phone} booking={booking} />
      <main>{children}</main>
      <Footer location={location} phone={phone} />
      <ToastContainer />
      <MobileButtons isVisible={isVisible} phone={phone} booking={booking} />
    </Fragment>
  );
};

export default Layout;
