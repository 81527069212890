import { NavLink } from 'react-router-dom';

import './services.scss';

const Services = ({ location }) => (
  <section className="services">
    <div className="container">
      <div className="title-wrapper">
        <h2 className="section-title">Appliance services</h2>

        <span>/04/ we fix</span>
      </div>

      <div className="services__inner">
        <NavLink to={`/${location.slug}/refrigerator-repair`}>
          <div className="icon">
            <img src="../../assets/services/refrigerator.svg" alt="Refrigerator Repair" />
          </div>
          <div className="title">Refrigerator Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/freezer-repair`}>
          <div className="icon">
            <img src="../../assets/services/freezer.svg" alt="Freezer Repair" />
          </div>
          <div className="title">Freezer Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/dishwasher-repair`}>
          <div className="icon">
            <img src="../../assets/services/dishwasher.svg" alt="Dishwasher Repair" />
          </div>
          <div className="title">Dishwasher Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/washer-repair`}>
          <div className="icon">
            <img src="../../assets/services/washer.svg" alt="Washer Repair" />
          </div>
          <div className="title">Washer Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/oven-repair`}>
          <div className="icon">
            <img src="../../assets/services/oven.svg" alt="Oven Repair" />
          </div>
          <div className="title">Oven Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/stove-repair`}>
          <div className="icon">
            <img src="../../assets/services/stove.svg" alt="Stove Repair" />
          </div>
          <div className="title">Stove Repair</div>
        </NavLink>
        <NavLink to={`/${location.slug}/dryer-repair`}>
          <div className="icon">
            <img src="../../assets/services/dryer.svg" alt="Dryer Repair" />
          </div>
          <div className="title">Dryer Repair</div>
        </NavLink>
        <div className="image">
          <img src="../../assets/services.svg" alt="Services" />
        </div>
      </div>
    </div>
  </section>
);

export default Services;
