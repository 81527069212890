import './areas.scss';

const Areas = ({ areas }) => (
  <section className="areas">
    <div className="container">
      <div className="title-wrapper">
        <h2 className="section-title">Areas We Serve</h2>

        <span>/08/ service area</span>
      </div>

      <div className="areas__inner">
        <div>
          <img src="../../assets/areas.svg" alt="Areas we serve" />
        </div>

        <ul>
          {areas.map((el, index) => (
            <li key={index}>{el.area}</li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default Areas;
