import Slider from 'react-slick';

import './brands.scss';

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const Brands = ({ brands }) => (
  <section className="brands">
    <div className="container">
      <div className="brands__inner">
        <div className="title-wrapper">
          <h2 className="section-title">We Fix All Makes & Models</h2>

          <span>/06/ brands</span>
        </div>

        <Slider className="brands__list" {...slider_settings}>
          {brands
            .sort((a, b) => {
              if (a > b) return -1;
              return 1;
            })
            .map((brand, index) => (
              <div key={index}>
                <img src={brand.url} alt={brand.alt} />
              </div>
            ))}
        </Slider>

        <ul className="brands__array">
          {brands.map((el, index) => (
            <li key={index}>{el.alt}</li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default Brands;
