import { Link } from 'react-router-dom';

import './mobileButtons.scss';

const MobileButtons = ({ isVisible, phone, booking }) => (
  <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
    <Link to={`tel:${phone}`}>{phone}</Link>
    <Link to={booking}>Book Online</Link>
  </div>
);

export default MobileButtons;
