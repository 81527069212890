import './about.scss';

const About = ({ data }) => (
  <section className="about">
    <div className="container">
      <div className="about__inner">
        <div className="title-wrapper">
          <h2 className="section-title">{data.title} Experts</h2>

          <span>/03/ about us</span>
        </div>

        <div className="about__content">
          <div className="text" dangerouslySetInnerHTML={{ __html: data.content }} />

          <div className="image">
            <img src={data.image} alt={data.title} />
          </div>
        </div>

        <div className="about__content--static">
          <div className="image">
            <div>
              <img src="https://admin.well-done-service.com/wp-content/uploads/2024/06/1.webp" alt="team member" />
              <img src="https://admin.well-done-service.com/wp-content/uploads/2024/06/2.webp" alt="team member" />
            </div>

            <div>
              <img src="https://admin.well-done-service.com/wp-content/uploads/2024/06/3.webp" alt="team member" />
            </div>
          </div>

          <div className="text">
            <h3>About</h3>

            <p>
              Welcome to Well Done Appliance Repair, where your household appliances are in the best hands. Recognized
              as the top-rated appliance repair company, we are dedicated to bringing your appliances back to their
              best.
            </p>

            <p>
              What makes us stand out? Passion, expertise, and a commitment to excellence. We believe that every
              appliance has a story and a purpose. When they break down, it disrupts your daily routine. Our mission is
              to restore that balance in the quickest and most efficient way.
            </p>
            <p>
              Our team is not just trained; they are the best in the business. With vast experience across all major
              brands and appliance types, we ensure every repair is Well Done. We value your trust and aim to offer
              seamless service every time.
            </p>

            <p>
              <strong>
                Choose reliability. Choose expertise. Choose Well Done Appliance Repair for all your appliance needs.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
