import { Link } from 'react-router-dom';
import './home.scss';

const HomeScreen = ({ locations }) => (
  <section className="home">
    <div className="container">
      <div className="home__inner">
        <img src="../../assets/header-logo.svg" alt="Well Done Appliance Repair" />

        <p>We work in these locations:</p>

        <ul>
          {locations.map((el) => (
            <li key={el.id}>
              <Link to={el.slug}>{el.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default HomeScreen;
