import { Link } from 'react-router-dom';
import './cta.scss';

const CallToAction = ({ title, booking, phone }) => (
  <section className="cta">
    <div className="container">
      <div className="cta__inner">
        <div className="text">
          <h2 className="section-title">Need Your {title.split(' ')[0]} Fixed?</h2>

          <Link to={booking} className="btn dark">
            Book a Service
          </Link>

          <div className="phone">
            or call us <Link to={`tel:${phone}`}>{phone}</Link>
          </div>
        </div>

        <div className="image">
          <img src="../../assets/cta.svg" alt="Book a Service" />
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
