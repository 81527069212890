import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import './reviews.scss';

export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ reviews }) => (
  <section className="reviews">
    <div className="container">
      <div className="reviews__inner">
        <div className="title-wrapper">
          <h2 className="section-title">What our customers say</h2>

          <span>/05/testimonials</span>
        </div>

        <Slider className="reviews__list" {...sliderSettings}>
          {reviews.map((el, index) => (
            <ReviewCard {...{ review: el, key: index }} />
          ))}
        </Slider>
      </div>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.source) {
    case 'Facebook':
      icon = faFacebookF;
      break;
    case 'Yelp':
      icon = faYelp;
      break;
    default:
      icon = faGoogle;
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="icon" />

      <p>{review.text}</p>

      <div className="meta">
        <div className="logo">
          <FontAwesomeIcon icon={icon} />
        </div>

        <div className="customer">
          <p className="name">{review.name}</p>

          <p className="date">{review.date}</p>
        </div>

        <div className="stars">
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />

          <span>5.0</span>
        </div>
      </div>
    </div>
  );
};
