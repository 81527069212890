import { Link } from 'react-router-dom';
import './callUs.scss';

const CallUs = ({ title, phone }) => (
  <section className="call-us">
    <div className="container">
      <div className="call-us__inner">
        <div className="title">
          <h2 className="section-title">
            {title.split(' ')[0]} broken?
            <br />
            Let us help you!
          </h2>

          <p>
            Appliance issues? No worries! With Well Done Appliance Repair, you're in expert hands. Reliable service is
            just a phone call away.
          </p>
        </div>

        <div className="links">
          <Link to={`tel:${phone}`} className="btn green">
            {phone}
          </Link>
          <span>Call us now!</span>
        </div>

        <div className="payments">
          <img src="../../assets/payments/mastercard.svg" alt="Mastercard" />

          <img src="../../assets/payments/visa.svg" alt="Visa" />

          <img src="../../assets/payments/discover.svg" alt="Discover" />

          <img src="../../assets/payments/ae.svg" alt="AE" />
        </div>
      </div>
    </div>
  </section>
);

export default CallUs;
