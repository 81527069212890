import { Link } from 'react-router-dom';

import './thanks.scss';

const ThankYou = () => (
  <section className="thanks">
    <div className="container">
      <div className="thanks__inner">
        <h1>Thank You!</h1>
        <p>We’ll respond to your request shortly!</p>

        <p>Looking forward to serving you!</p>

        <Link to="/" className="btn dark">
          Back To Home Page
        </Link>
      </div>
    </div>
  </section>
);

export default ThankYou;
