import { Link, NavLink } from 'react-router-dom';
import './footer.scss';

const Footer = ({ location, phone }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="page-footer__inner">
          <NavLink to={`/${location.toLowerCase().split(' ').join('-')}`} className="logo">
            <img src="../../assets/footer-logo.svg" alt="Well Done Appliance Repair" />
          </NavLink>

          <Link to={`tel:${phone}`} className="phone">
            {phone}
          </Link>

          <Link to={`mailto:well.done.service@gmail.com`} className="email">
            well.done.service@gmail.com
          </Link>

          <div className="underline">
            <span>
              ©{currentYear}{' '}
              <NavLink to={`/${location.toLowerCase().split(' ').join('-')}`}>Well Done Appliance Repair.</NavLink> All
              Rights Reserved.
            </span>

            <Link to="https://industry.toporin.com/appliance-repair" target="_blank" rel="noReferrer">
              Designed by <strong>Toporin Studio®</strong> in California
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
