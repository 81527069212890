import { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import './header.scss';

const Header = ({ location, phone, booking }) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="page-header__inner">
          <NavLink to={`/${location.toLowerCase().split(' ').join('-')}`} className="logo">
            <img src="../../assets/header-logo.svg" alt="Well Done Appliance Repair" />
          </NavLink>

          <nav>
            <Link to={`tel:${phone}`} className="phone">
              {phone}
            </Link>

            <Link to={booking} className="btn green">
              Book Now
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
