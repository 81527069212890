import { Link } from 'react-router-dom';
import './hero.scss';

const Hero = ({ title, phone, booking }) => (
  <section className="hero">
    <div className="container">
      <div className="hero__inner">
        <div className="content">
          <h1>
            Well Done <br />
            {title}
          </h1>

          <p>Your Local Professionals</p>

          <div className="links">
            <Link to={booking} className="btn green">
              Book Now
            </Link>

            <span>
              or call us <Link to={`tel:${phone}`}>{phone}</Link>
            </span>
          </div>
        </div>

        <div className="image">
          <img src="../../assets/hero.svg" alt={`Well Done ${title}`} />
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
