import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Spinner } from './components';

import { PageTemplate, Home, ThankYou } from './pages';

export const ADMIN_URL = 'https://admin.well-done-service.com/wp-json/wp/v2';

const App = () => {
  const categories = useFetch(`${ADMIN_URL}/categories?per_page=100`);
  const pages = useFetch(`${ADMIN_URL}/posts?per_page=100`);
  const settings = useFetch(`${ADMIN_URL}/general_settings?slug=settings`);

  const [locations, setLocations] = useState();

  // Define locations
  useEffect(() => {
    if (categories.error || pages.error || settings.error)
      return toast.error('Data loading error. Please reload the page!');

    if (!categories.isLoading && !pages.isLoading && !settings.isLoading) {
      const locationsData = categories?.data.map((el) => ({
        phone: el.acf.phone_number,
        booking: el.acf.booking_link,
        areas: el.acf.service_areas,
        slug: el.slug,
        title: el.name,
        settings: settings.data[0].acf,
        pages: pages.data
          ?.map((page) => {
            if (page.categories.includes(el.id)) {
              const pageData = {
                title: page.title.rendered,
                image: page.acf.image,
                content: page.content.rendered,
                slug: page.slug,
              };

              return pageData;
            }

            return false;
          })
          .filter((page) => page !== undefined),
      }));

      setLocations(locationsData);
    }
  }, [categories, pages, settings]);

  if (!locations || settings.isLoading || categories.isLoading || pages.isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<Home cities={categories.data} pages={pages.data} />} />

      {locations.map((location) =>
        location.pages.map((page) => (
          <Fragment>
            <Route
              path={`/${location.slug}/${page.slug}`}
              element={
                <PageTemplate
                  location={{
                    title: location.title,
                    slug: location.slug,
                  }}
                  data={page}
                  settings={location.settings}
                  phone={location.phone}
                  title={page.title}
                  areas={location.areas}
                  booking={location.booking}
                />
              }
            />
            <Route
              path={`/${location.slug}`}
              element={<Navigate replace to={`/${location.slug}/appliance-repair`} />}
            />
          </Fragment>
        )),
      )}

      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
};

export default App;
