import { Layout } from '../components';

import {
  Hero,
  WhyUs,
  // GetQuote,
  About,
  Services,
  CallToAction,
  Reviews,
  Brands,
  Steps,
  Areas,
  CallUs,
} from '../sections';

const PageTemplate = ({ location, data, phone, title, areas, booking, settings }) => (
  <Layout
    pageId={data.slug}
    phone={phone}
    booking={booking}
    location={location.title}
    slug={location.slug}
    pageTitle={title}
  >
    <Hero phone={phone} title={title} booking={booking} />
    <WhyUs />
    {/* <GetQuote /> */}
    <About data={data} />
    <Services location={location} />
    <CallToAction title={title} booking={booking} phone={phone} />
    <Reviews reviews={settings.reviews} />
    <Brands brands={settings.brands} />
    <Steps />
    <Areas areas={areas} />
    <CallUs phone={phone} title={title} />
  </Layout>
);

export default PageTemplate;
