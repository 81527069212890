import CountUp from 'react-countup';

import './whyUs.scss';

const WhyUs = () => (
  <section className="why-us">
    <div className="container">
      <div className="why-us__inner">
        <div className="title-wrapper">
          <h2 className="section-title">Why Us</h2>

          <span>/01/ advantages</span>
        </div>

        <div className="counter">
          <div className="counter__item">
            <span className="number">
              <CountUp start={0} end={10} enableScrollSpy={true} scrollSpyDelay={0.5} delay={1} duration={2} />+
            </span>
            <span className="title">
              Years of <br />
              Experience
            </span>
          </div>

          <div className="counter__item">
            <span className="number">
              <CountUp start={0} end={15} enableScrollSpy={true} scrollSpyDelay={0.5} delay={1.5} duration={2} />+
            </span>
            <span className="title">
              Awards <br />
              Earned
            </span>
          </div>

          <div className="counter__item">
            <span className="number">
              <CountUp start={0} end={5000} enableScrollSpy={true} scrollSpyDelay={0.5} delay={2} duration={2} />+
            </span>
            <span className="title">
              Satisfied <br />
              Customers
            </span>
          </div>
        </div>

        <div className="reasons">
          <div className="reasons__item">
            <div className="icon">
              <img src="../../assets/reasons/1.svg" alt="Same-Day Repair Service" />
            </div>
            <div className="title">Experience You Can Trust</div>
            <div className="text">
              We only use genuine, high-quality parts for replacements. This ensures your appliance not only works but
              lasts longer after the repair
            </div>
          </div>

          <div className="reasons__item">
            <div className="icon">
              <img src="../../assets/reasons/2.svg" alt="Parts & Labor Warranty" />
            </div>
            <div className="title">Quality Parts & Service</div>
            <div className="text">
              We only use genuine, high-quality parts for replacements. This ensures your appliance not only works but
              lasts longer after the repair
            </div>
          </div>

          <div className="reasons__item">
            <div className="icon">
              <img src="../../assets/reasons/3.svg" alt="Affordable Price" />
            </div>
            <div className="title">Transparent Pricing</div>
            <div className="text">
              No hidden charges. No surprise fees. We provide you with a clear estimate before starting any repair,
              ensuring you know exactly what to expect
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyUs;
