import './steps.scss';

const Steps = () => (
  <section className="steps">
    <div className="container">
      <div className="steps__inner">
        <div className="title-wrapper">
          <h2 className="section-title">How to get our service? </h2>

          <span>/07/How it works?</span>
        </div>

        <div className="steps__list">
          <div>
            <div className="number">1</div>
            <div className="icon">
              <img src="../../assets/steps/1.svg" alt="Call for appointment" />
            </div>
            <div className="text">Call for an Appointment or Book Online</div>
          </div>

          <div>
            <div className="number">2</div>
            <div className="icon">
              <img src="../../assets/steps/2.svg" alt="Get a date &serial" />
            </div>
            <div className="text">
              Schedule
              <br />a Repair Date
            </div>
          </div>

          <div>
            <div className="number">3</div>
            <div className="icon">
              <img src="../../assets/steps/3.svg" alt="Repair service" />
            </div>
            <div className="text">Visit from Our Technician</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Steps;
